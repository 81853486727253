import React from 'react';
import { Field, Form } from 'react-final-form';

import Slide from 'components/Animations/Slide';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { FF_InputField, FF_SelectField, FF_TextAreaField } from 'components/Inputs';
import { Modal, ModalActions, ModalContent, ModalTitle } from 'components/Modal';
import ReceiptLine from 'components/ReceiptLine';
import Typography from 'components/Typography';
import { useEnquiries } from 'contexts/EnquiryContext';
import styles from './EnquiryDialog.module.css';

const EnquiryDialog = () => {
  const { countries, products, open, setOpen, sent, send, removeProduct } = useEnquiries();

  const onClose = () => setOpen(false);

  const SentModal = (
    <Modal>
      <ModalContent center>
        <div>
          <Typography component="p" align="center">
            Thank you!
          </Typography>
          <Typography component="p" color="faded" align="center">
            We will get back to you as soon as possible with your request.
          </Typography>
        </div>
      </ModalContent>
      <ModalActions>
        <Button color="white" onClick={onClose}>
          Close
        </Button>
      </ModalActions>
    </Modal>
  );
  const EnquiryModal = (
    <Modal onClose={onClose}>
      <Form onSubmit={send}>
        {({ handleSubmit }) => {
          return (
            <form className={styles.form} onSubmit={handleSubmit}>
              <ModalTitle align="center" className={styles.modalTitle}>
                Send a message to the gallery
              </ModalTitle>
              <ModalContent>
                <Field
                  component={FF_InputField}
                  block
                  name="email"
                  type="email"
                  label="From:"
                  placeholder="Enter your email"
                  required
                />
                <Field
                  component={FF_TextAreaField}
                  block
                  label="YOUR MESSAGE"
                  name="message"
                  resizable={false}
                  rows={4}
                  placeholder="Attach a message for the gallery"
                />
                <Field component={FF_SelectField} block label="YOUR COUNTRY" name="country">
                  {countries.map(country => (
                    <option key={`country-option-${country}`} value={country}>
                      {country}
                    </option>
                  ))}
                </Field>

                {products.map((p, k) => (
                  <ReceiptLine product={p} key={k} onRemove={() => removeProduct(p.id)} />
                ))}
              </ModalContent>
              <ModalActions>
                <Button color="black" onClick={onClose}>
                  + Add more items
                </Button>
                <Button color="transparent" disabled={!products.length} type="submit">
                  Send request
                </Button>
              </ModalActions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Slide in={open}>{sent ? SentModal : EnquiryModal}</Slide>
    </Dialog>
  );
};
export default EnquiryDialog;
